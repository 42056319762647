<template>
    <div v-if="$can('read', 'candidates')">
    <b-card>
        
      <div>
        <app-collapse class="mb-1" style="border-radius: 50px !important">
          <!-- <span class="text-primary">
              <feather-icon
                    icon="FilterIcon"
                    size="18"
                    class="cursor-pointer"
              /> Filters
            </span> -->
          <app-collapse-item title="Click for advanced filters" class="text-primary">
            
            <b-row>
              <b-col md="3" xl="3" xs="3" >
                <b-form-group
                  label="Select Status"
                  label-for="select-status">
                  <v-select
                      v-model="searchFilters.statusSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="statusOptions"
                      :reduce="(option) => option.title"
                      multiple
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col md="3" xl="3" xs="3"  v-if="role === 'ADMIN' || role === 'HR' || role === 'MIS' || role === 'MASTER'"> -->
              <b-col md="3" xl="3" xs="3"  v-if="$can('read', 'manager_filter')">  
                <b-form-group
                  label="Select Account Manager"
                  label-for="select-account-manager">
                  <v-select
                      v-model="searchFilters.managerSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="accountManagers"
                      :reduce="(option) => option.emp_id"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3" >
                <b-form-group
                  label="Select Client"
                  label-for="select-client">
                  <v-select
                      v-model="searchFilters.clientSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="clients"
                      :reduce="(option) => option.title"
                      multiple
                      :close-on-select="false"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3"  v-if="$can('read', 'recruiter_filter')">
                <b-form-group
                label="Select Recruiter"
                label-for="select-recruiter">
                <v-select
                      v-model="searchFilters.recruiterSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="recruiters"
                      :reduce="(option) => option.emp_id"
                      multiple
                      :close-on-select="false"
                  />
                  <!-- <v-select
                      v-model="searchFilters.recruiterSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="recruiters"
                      :reduce="(option) => option.emp_id"
                      multiple
                      :close-on-select="false"
                  /> -->
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3"  v-if="$can('read', 'manager_filter')">
                <b-form-group
                label="Select Revenue"
                label-for="select-revenue">
                  <v-select
                      v-model="searchFilters.revenueSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="revenues"
                      :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
                  
              <!-- <b-col md="3" xl="3" xs="3"  v-if="role === 'ADMIN' || role === 'HR' || role === 'MIS' || role === 'MASTER' || role === 'ACCOUNT-MANAGER'"> -->
              <b-col md="3" xl="3" xs="3"  v-if="$can('read', 'recruiter_filter')">
                
                <b-form-group label="Job Status"
                label-for="job-status">
                  <v-select
                    v-model="searchFilters.jobStatus"
                    :options="jobStatusOptions"
                    label="title"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3" >
                <b-form-group label="Vertical"
                label-for="vertical">
                  <v-select
                    v-model="searchFilters.vertical"
                    :options="verticalOptions"
                    label="title"
                    :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3" >
                <b-form-group label="Category"
                  label-for="category">
                  <v-select
                    v-model="searchFilters.category"
                    :options="categoryOptions"
                    label="title"
                    :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3" >
                <b-form-group label="Job Type"
                  label-for="job-type">
                  <v-select
                    v-model="searchFilters.jobType"
                    :options="jobTypeOptions"
                    label="title"
                    :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3" xs="3" >  
                <b-form-group label="Payroll"
                label-for="payroll">
                  <v-select
                    v-model="searchFilters.payroll"
                    :options="payrollOptions"
                    label="title"
                    :reduce="(option) => option.title"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <!-- <span class="text-danger font-weight-bolder">Exclude Fields</span>
            <b-row>
              <b-col>

              </b-col>
            </b-row> -->
          </app-collapse-item>
        </app-collapse>
          <hr>
          <b-row class="mt-1">
            <b-col md="3" xl="3" xs="3" >
              <b-form-group label="Date Filters"
              >
                <b-form-radio-group
                
                  v-model="searchFilters.dateFieldSelected"
                  :options="dateFieldOptions"
                  class="demo-inline-spacing"
                  
                  name="radio-inline"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" xs="3" >
              <b-form-group
                label="Start Date"
                label-for="start-date"
              >
                <flat-pickr
                  v-model="searchFilters.startDate"
                  class="form-control"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                />
                <!-- <small v-if="!startDate" class="text-danger">Start Date is Mandatory</small> -->
              </b-form-group>
            </b-col>    
            <b-col md="3" xl="3" xs="3" >
              <b-form-group
                label="End Date"
                label-for="end-date"
              >
                <flat-pickr
                  v-model="searchFilters.endDate"
                  class="form-control"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                />
                <!-- <small v-if="!endDate" class="text-danger">End Date is Mandatory</small> -->
              </b-form-group>
            </b-col>
            <b-col md="3" xl="3" xs="3" >
              <b-button class="mt-2" variant="outline-primary"  
                  @click="getMyCandidates()" 
              >Filter</b-button>
              <span class="px-1" @click="clearFilter()">Clear Filter</span>
            </b-col>
          </b-row>
        </div>
        <hr>
        <span v-if="$can('read', 'recruiter_filter')" class="text-warning">
            ***Please Note for roles above Team Lead we only propulate Shortlisted & above Status, for other status use above filters.
        </span>
        <div class="demo-inline-spacing" style="font-weight: 430;">
          <!-- <b-badge pill variant="light-primary" @click="filterCandidates('ALL')">ALL</b-badge> -->
            <li class="no-bullets">
                
                <b-badge pill variant="light-primary" @click="getMyCandidates()">ALL</b-badge>
                  :
                <span class="text-bold">
                    {{ totalCount[0].count }}
                </span>
            </li>
            <li class="no-bullets" v-for="s in statusWiseData" :key="s.candidateStatus">
                <!-- <b-badge pill  @click="filterCandidates(s.candidateStatus)" :class="resolveCandidateStatusVariant(s.candidateStatus)"> -->

                <b-badge pill  @click="getMyCandidates(s.candidateStatus)" :class="resolveCandidateStatusVariant(s.candidateStatus)">
                    {{ s.candidateStatus }}
                </b-badge> :
                <span class="text-bold">
                    {{ s.count }}
                </span>
            </li>
        </div>
        <!-- <div v-if="selections < 1 && joined < 1 && offered < 1">
          
        </div> -->
      <div class="mt-1" style="width:175px" v-if="$can('read', 'manager_filter')">
        <download-excel :data="items" :name="filename" v-if="isReadyToDownload">
          <button type="button" class="btn btn-success mb-1">Download Report</button>
        </download-excel>
      </div>
        <div v-if="response_error"> {{ response_error }}</div>
    </b-card>
        
    <b-card>
    <b-row>
      <b-col
        md="3"
        sm="6"
        lg="3"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        sm="6"
        lg="3"
        class="my-1"
        style="font-weight: 430;"
      > Total Candidates : {{ totalRows }}
    </b-col>
      <b-col
        md="6"
        sm="6"
        lg="6"
        class="my-1"
      >
        
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="searchFilters.filter"
              type="search"
              placeholder="Type to Search"
              trim
            />
            <!-- <b-input-group-append>
              <b-button variant="success"
                @click="filterDataOnInput"
              >
                Search
              </b-button>
            </b-input-group-append> -->
            <b-input-group-append>
              <b-button
                :disabled="!searchFilters.filter"
                @click="searchFilters.filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        
      </b-col>
      <b-col cols="12">
        <span class="text-danger">{{ error }} </span>
      </b-col>
      <b-col cols="12" v-if="loading">
        <div class="text-center">
          <b-spinner variant="primary" label="Text Centered" />
        </div>
      </b-col>
      
      <b-col cols="12">
        <b-table
          id="my-candidates-table"
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="searchFilters.filter"
          :filter-included-fields="filterOn"
          filter-debounce="800"
          @filtered="onFiltered"
          style="width: 100% !important"
        > 
        <!-- :filter="searchFilters.filter"
          :filter-included-fields="filterOn"
          filter-debounce="800"
          @filtered="onFiltered"   -->
        <template #cell(actions)="data" >
              <!-- <feather-icon
                        icon="EditIcon"
                        size="16"
                        class="align-middle text-body"
                        variant="outline-primary"
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-modal.modal-select2
                        @click="sendCandidateItemToModal(data.item)"
                     />      -->
                
              <b-dropdown
                variant="link"
                no-caret
                class="chart-dropdown"
                toggle-class="p-0"
                right
                
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item href="#"
                  @click="sendCandidateItemToModal(data.item)" v-if="data.item.candidateStatus === 'Duplicate' || data.item.candidateStatus === 'Shortlisted' || data.item.candidateStatus === 'Offered' || data.item.candidateStatus === 'Joined' || data.item.candidateStatus === 'OfferDrop' && $can('update','candidates')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-select2>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                variant="outline-primary"
                            /> Update Data
                </b-dropdown-item>
                <b-dropdown-item href="#"
                  @click="showContractualCandidateData(data.item)" v-if="(data.item.candidateStatus === 'Joined'|| data.item.candidateStatus === 'Early Attrition') && (data.item.position_type === 'Contractual' || data.item.position_type === 'Cost Plus') && data.item.payroll === 'Sattva Payroll' && $can('read','contractual_candidates')" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-contractual-show-data>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                variant="outline-primary"
                            /> Update Contractual Revenue
                </b-dropdown-item>
                <b-dropdown-item href="#" v-if="data.item.candidateStatus === 'ScreenSelect' || data.item.candidateStatus === 'Shortlisted' || data.item.candidateStatus === 'Inprocess' || data.item.candidateStatus === 'OnHold' && $can('create','interview')"
                  @click="sendCandidateItemToModal(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-date>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                
                                variant="outline-primary"
                            /> Schedule Interview
                </b-dropdown-item>
                <!-- <b-dropdown-item href="#" v-if="data.item.candidateStatus === 'UploadedBySourcer' || data.item.candidateStatus === 'UploadedByRecruiter' || data.item.candidateStatus === 'AssignedByMassMail' || data.item.candidateStatus === 'Not Answered' || data.item.candidateStatus === 'Not Interested'  || data.item.candidateStatus === 'Not Reachable'  || data.item.candidateStatus === 'Call Back'"
                  @click="sendCandidateItemToModal(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-date>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                variant="outline-primary"
                            /> Mark as Not Interested
                </b-dropdown-item>
                <b-dropdown-item href="#" v-if="data.item.candidateStatus === 'UploadedBySourcer' || data.item.candidateStatus === 'UploadedByRecruiter' || data.item.candidateStatus === 'AssignedByMassMail' || data.item.candidateStatus === 'Not Answered' || data.item.candidateStatus === 'Not Interested'  || data.item.candidateStatus === 'Not Reachable'  || data.item.candidateStatus === 'Call Back'"
                  @click="sendCandidateItemToModal(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-date>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                variant="outline-primary"
                            /> Mark as Not Relevant
                </b-dropdown-item>
                <b-dropdown-item href="#" v-if="data.item.candidateStatus === 'UploadedBySourcer' || data.item.candidateStatus === 'UploadedByRecruiter' || data.item.candidateStatus === 'AssignedByMassMail' || data.item.candidateStatus === 'Not Answered' || data.item.candidateStatus === 'Not Interested'  || data.item.candidateStatus === 'Not Reachable'  || data.item.candidateStatus === 'Call Back'"
                  @click="sendCandidateItemToModal(data.item)" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-date>
                  <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="align-middle text-body"
                                variant="outline-primary"
                            /> Mark as Call Relevant Status
                </b-dropdown-item> -->
                
              </b-dropdown>
                
            </template>
            <template #cell(candidate_name)="data" >
                <router-link v-if="$can('read','candidates')"
                              :to="{
                                name: 'apps-candidates-view',
                                params: { id: data.item.cid, jobId: data.item.jid },
                                query: { position_name: data.item.position_name,
                                         client: data.item.client,
                                         attachments: data.item.attachments,
                                         recruiter: data.item.rec_id,
                                         locations: data.item.locations,
                                         sourcer: data.item.sourcer_id,
                                         am_name: data.item.account_mgr,
                                         position_type: data.item.position_type,
                                         vertical: data.item.vertical,
                                         category: data.item.category,
                                         payroll: data.item.payroll
                                }
                              }"
                            >
                            <!-- v-b-tooltip.hover.top="data.item.contact_no + '\n' + data.item.email" -->
                    <div class="text-nowrap" style="font-weight: 430;  width: 150px !important; text-overflow:ellipsis; overflow:hidden ;white-space: initial"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      
                      v-b-popover.hover.right="data.item.candidate_name.replace(/SHC-\d+ - /g, '').trim() + '\n' + data.item.contact_no + '\n' + data.item.email"
                      id="popover-button-contextmenu"
                      title="Candidate Detail"
                      variant="outline-primary">
                        {{ data.item.candidate_name.replace(/SHC-\d+ - /g, '').trim() }} <br/>
                        {{ data.item.candidate_name.match('SHC-[0-9]+') }} 
                    </div>
                </router-link>
            </template>
            <template #cell(position_name)="data" >
                <router-link :to="'/apps/view-job/' + data.item.jid">
                  <div class="text-nowrap">
                    {{ data.item.position_name }} <span v-if="data.item.position_type === 'Contractual'">(Contractual)</span><br/>
                    {{ data.item.client }} 
                  </div>
                </router-link>
            </template>
            <template #cell(candidateStatus)="data" >
                <div class="text-nowrap">
                  {{ data.item.candidateStatus }} <br/>
                  {{ data.item.source }} 
                </div>
            </template>
            <template #cell(sourcer)="data" >
                <div class="text-nowrap">
                  {{ data.item.sourcer }} (S)<br/>
                  {{ data.item.recruiter }} (R)
                </div>
            </template>
            <template #cell(createdAt)="data" >
                <div class="text-nowrap">
                  {{ data.item.createdAt.replace(/T.*/, '') }} (A)
                </div>
                <div class="text-nowrap">
                  {{ data.item.updatedAt }} (U)
                </div>
            </template>
            <template #cell(selection_date)="data" >
                <div class="text-nowrap">
                  {{ data.item.selection_date }} 
                </div>
            </template>
            <template #cell(offered_date)="data" >
                <div class="text-nowrap">
                  {{ data.item.offered_date }} 
                </div>
            </template>
            <template #cell(joining_date)="data" >
                <div class="text-nowrap">
                  {{ data.item.joining_date }} 
                </div>
            </template>
            <template #cell(offered_ctc)="data" >
                <!-- <div class="text-nowrap" v-if="data.item.client === 'Sattva Human Pvt Ltd' && (role === 'ADMIN' || role === 'HR' || role === 'MASTER')"> -->
                <div class="text-nowrap" v-if="data.item.client === 'Sattva Human Pvt Ltd' && ($can('read','admin') || $can('read','hr') || $can('read','master'))">  
                  {{ data.item.offered_ctc }} 
                </div>
                <div class="text-nowrap" v-if="data.item.client !== 'Sattva Human Pvt Ltd'">
                  {{ data.item.offered_ctc }} 
                </div>
            </template>
            <template #cell(revenue)="data">
                <!-- <div class="text-nowrap" v-if="data.item.client === 'Sattva Human Pvt Ltd' && (role === 'ADMIN' || role === 'HR' || role === 'MASTER' || role === 'MIS')"> -->
                <div class="text-nowrap" v-if="data.item.client === 'Sattva Human Pvt Ltd' && ($can('read','admin') || $can('read','hr') || $can('read','master') || $can('read','mis'))">
                  {{ data.item.revenue }} <br/>
                  {{ data.item.revenue_confirmed }}
                </div>
                <div class="text-nowrap" v-if="data.item.client !== 'Sattva Human Pvt Ltd'">
                  {{ data.item.revenue }} <br/>
                  {{ data.item.revenue_confirmed }}
                </div>
            </template>
            
        </b-table>
      </b-col>
  
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
    </b-card>
    <b-modal
      id="modal-select2"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="submit"
      cancel-variant="outline-secondary"
      @ok="updateCandidateDetails"
    >
    <!-- <b-form v-if="currentStatus === 'Duplicate' && (role === 'TALENT-ADVISOR' || role === 'TALENT-ADVISOR-SOURCER' || role === 'ACCOUNT-MANAGER')"> -->
      <b-form v-if="currentStatus === 'Duplicate' && ($can('read','sme_talent_acquisition') || $can('read','talent_acquisition') || $can('read','account-manager') || $can('read','program-manager'))">  
        <b-form-group
          label="Enter Duplicate Date *"
          label-for="duplicate-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="duplicate_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <small v-if="!selection_date && error" class="text-danger">{{ error }}</small>
      </b-form>
      <!-- <b-form v-if="currentStatus === 'Shortlisted' && (role === 'TALENT-ADVISOR' || role === 'TALENT-ADVISOR-SOURCER' || role === 'ACCOUNT-MANAGER')"> -->
      <b-form v-if="currentStatus === 'Duplicate' && ($can('read','sme_talent_acquisition') || $can('read','talent_acquisition') || $can('read','account-manager') || $can('read','program-manager'))">    
        <b-form-group
          label="Enter Selection Date *"
          label-for="selection-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="selection_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <small v-if="!selection_date && error" class="text-danger">{{ error }}</small>
      </b-form>
      <!-- <b-form v-if="currentStatus === 'Offered' && (role === 'TALENT-ADVISOR' || role === 'TALENT-ADVISOR-SOURCER' || role === 'ACCOUNT-MANAGER')"> -->
      <b-form v-if="currentStatus === 'Duplicate' && ($can('read','sme_talent_acquisition') || $can('read','talent_acquisition') || $can('read','account-manager') || $can('read','program-manager'))">    
        <b-form-group
          label="Enter Offer Release Date *"
          label-for="offer-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="offered_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <small v-if="!offered_date && error" class="text-danger">{{ error }}</small>
        <b-form-group
          label="Enter Joining Date"
          label-for="joining-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="joining_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <b-form-group 
            label="Fixed CTC"
            label-for="offeredCTC"
          >     
            <b-form-input
              class="form-control"
              id="offeredCTC" 
              v-model="offeredCTC" 
              placeholder="Enter Fixed CTC in Rupees(complete amount like 100000.00)"
              type="number"
              step=".01"
            />
        </b-form-group>
        <b-form-group 
            label="Variable CTC"
            label-for="variableCTC"
          >     
            <b-form-input
              class="form-control"
              id="variableCTC" 
              v-model="variableCTC" 
              placeholder="Enter Variable CTC in Rupees(complete amount like 100000.00)"
              type="number"
              step=".01"
            />
        </b-form-group>
        <b-form-group 
            label="Band"
            label-for="band"
          >     
            <b-form-input
              class="form-control"
              id="band" 
              v-model="band" 
              placeholder="Enter Employee Band as mentioned in offer letter"
            />
        </b-form-group>
      </b-form>
      <!-- <b-form v-if="(currentStatus === 'Shortlisted' || currentStatus === 'Offered' || currentStatus === 'Joined' || currentStatus === 'OfferDrop' || currentStatus === 'NotOffered')  && (role === 'HR' || role === 'MIS' || role === 'ADMIN' || role === 'MASTER')"> -->
      <b-form v-if="(currentStatus === 'Shortlisted' || currentStatus === 'Offered' || currentStatus === 'Joined' || currentStatus === 'OfferDrop' || currentStatus === 'NotOffered')  && ($can('read','admin') || $can('read','hr') || $can('read','master') || $can('read','program-manager') || $can('read','mis'))">
        <b-form-group
          label="Enter Selection Date *"
          label-for="selection-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="selection_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <b-form-group
          label="Enter Offer Release Date *"
          label-for="offer-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="offered_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <b-form-group 
            label="Fixed CTC"
            label-for="offeredCTC"
          >     
            <b-form-input
              class="form-control"
              id="offeredCTC" 
              v-model="offeredCTC" 
              placeholder="Enter CTC in Rupees(complete amount like 10000.00)"
              type="number"
              step=".01"
            />
        </b-form-group>
        <b-form-group 
            label="Variable CTC"
            label-for="variableCTC"
          >     
            <b-form-input
              class="form-control"
              id="variableCTC" 
              v-model="variableCTC" 
              placeholder="Enter Variable CTC in Rupees(complete amount like 100000.00)"
              type="number"
              step=".01"
            />
        </b-form-group>
        <b-form-group 
            label="Band"
            label-for="band"
          >     
            <b-form-input
              class="form-control"
              id="band" 
              v-model="band" 
              placeholder="Enter Employee Band as mentioned in offer letter"
            />
        </b-form-group>
        <b-form-group
          label="Enter Joining Date"
          label-for="joining-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="joining_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <b-form-group
          label="Enter Revenue(Revenue generated Towards Company) *"
          label-for="revenue-gen"
        >
            <b-form-input
                v-model="revenue"
                type="number"
                step=".01" 
            />
        </b-form-group>
        <small v-if="!revenue && error" class="text-danger">{{ error }}</small>
        <b-form-group label="Revenue Confirmed *" label-for="revenue-confirmed">
            <v-select
                v-model="revenue_confirmed"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="option"
            />
        </b-form-group>
        <small v-if="!revenue_confirmed && error" class="text-danger">{{ error }}</small>
      </b-form>
      <b-form v-else-if="currentStatus === 'Joined'">
        <b-form-group
          label="Enter Joining Date *"
          label-for="joining-date"
        >
        <flat-pickr
            class="form-control"
            id="dob" 
            v-model="joining_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <small v-if="!joining_date && error" class="text-danger">{{ error }}</small>
        <b-form-group v-if="currentStatus === 'Offered' || currentStatus === 'Joined' || currentStatus === 'OfferDrop' || currentStatus === 'NotOffered'"
            label="Fixed CTC"
            label-for="offeredCTC"
          >     
            <b-form-input
              class="form-control"
              id="offeredCTC" 
              v-model="offeredCTC" 
              placeholder="Enter CTC in Rupees(complete amount like 100000.00)"
              type="number"
              step=".01"
            />
        </b-form-group>

        
        
      </b-form>
      <!-- <b-form v-else-if="currentStatus === 'Early Attrition'">
        <b-form-group
          label="Enter Attrition Date *"
          label-for="attrition-date"
        >
        <flat-pickr
            class="form-control"
            id="attrition_date" 
            v-model="attrition_date" 
            placeholder="YYYY-MM-DD"
            :config="{ enableTime: false,dateFormat: 'd M Y'}"
        />
        </b-form-group>
        <small v-if="!attrition_date && error" class="text-danger">{{ error }}</small>
        
      </b-form> -->
    </b-modal>
    <b-modal
      id="modal-date"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="submit"
      cancel-variant="outline-secondary"
      @ok="scheduleInterview"
    >
    <b-form >
        <b-form-group
          label="Enter Interview Date Time *"
          label-for="interview-date"
        >
        <flat-pickr
            class="form-control"
            v-model="interview_date" 
            :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
        />
        </b-form-group>
        <small v-if="!interview_date && error" class="text-danger">{{ error }}</small>
      </b-form>
    </b-modal>
    
    <b-modal
      id="modal-contractual-show-data"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="Add New Month Data"
      cancel-variant="outline-secondary"
      size="xl"
      @ok="openAddContractualModal"
      v-if="$can('read','contractual_candidates')"
    >
    <!-- <div v-if="!contractDataPresent"> -->
      <b-button variant="primary" @click="openAddContractDetailsModal">Add/Edit Contract Details</b-button>
    <!-- </div> -->
    <div>
      <h4 class="text-center">
        Candidate Contract Detail Data
      </h4>
      <b-table
        striped
        hover
        responsive
        :items="contractualDetailItem"
        :fields="contractualDetailFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filterContractual"
        @filtered="onFilteredContractual">
        <template #cell(actions)="data" >
                <div class="text-nowrap" @click="openAddContractDetailsModal(data.item.id, data.item.candidate_id, data.item.job_id)">
                  <feather-icon size="21" icon="EditIcon" class="text-info"/>
                </div>
            </template>
      </b-table>
    </div> 
    <h4 class="text-center">
        Candidate Contract Monthly Salary Data
    </h4>
    <b-table
        striped
        hover
        responsive
        :items="contractualItems"
        :fields="contractualFields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filterContractual"
        @filtered="onFilteredContractual">
        <template #cell(actions)="data" >
                <div class="text-nowrap" @click="openUpdateContractualModal(data.item.id, data.item.candidate_id, data.item.job_id)" v-if="$can('update','contractual_candidates')">
                  <feather-icon size="21" icon="EditIcon" class="text-info"/>
                </div>
            </template>
      </b-table>
    </b-modal>
    <b-modal
      id="modal-contractual-details-add-data"
      ref="modal-contractual-details-add-data"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="submit"
      cancel-variant="outline-secondary"
      size="lg"
      v-if="$can('create','contractual_candidates')"
      @ok="addContractualDetails"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Contract Start Date"
              label-for="contract-start-date"
            >
              <flat-pickr
                  class="form-control"
                  id="contract-start-date" 
                  v-model="contractDetails.contractStartDate" 
                  placeholder="DD-Mon-YYYY"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                 
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Contract End Date"
              label-for="contract-end-date"
            >
            <flat-pickr
                class="form-control"
                id="contract-end-date" 
                v-model="contractDetails.contractEndDate" 
                placeholder="DD-Mon-YYYY"
                :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
            />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Rate Card"
              label-for="rate-card"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractDetails.rateCard" 
                  placeholder="Enter Bill Rate Card in Rupees"
                  type="number"
                  step=".01"
                  
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Monthly CTC"
              label-for="monthlyCTC"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractDetails.monthlyCTC" 
                  placeholder="Enter Gross Monthly CTC of Employee"
                  type="number"
                  step=".01"
                  
                  
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Probation Days"
              label-for="probation-days"
            >     
              <b-form-input
                class="form-control"
                id="probation-days" 
                v-model="contractDetails.probationDays" 
                placeholder="Enter Candidate Probation Days"
              />
            </b-form-group>
          </b-col>
          
        </b-row>  
        
        
      </b-form>
    </b-modal>
    <b-modal
      id="modal-contractual-add-data"
      ref="modal-contractual-add-data"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="submit"
      cancel-variant="outline-secondary"
      size="lg"
      v-if="$can('create','contractual_candidates')"
      @ok="addContractualCandidateData"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Contract Start Date"
              label-for="contract-start-date"
            >
              <flat-pickr
                  class="form-control"
                  id="contract-start-date" 
                  v-model="contractualEmployee.contractStartDate" 
                  placeholder="DD-Mon-YYYY"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                  @input="computeContractEndDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Contract End Date"
              label-for="contract-end-date"
            >
            <flat-pickr
                class="form-control"
                id="contract-end-date" 
                v-model="contractualEmployee.contractEndDate" 
                placeholder="DD-Mon-YYYY"
                :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
            />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Contract Month"
              label-for="contract-month"
            >
            <v-select
                id="contract-month" 
                v-model="contractualEmployee.contractMonth" 
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="YYYY-MM-DD"
                :options="monthsList"
                :reduce="(option) => option.title"
                @change="computeRateAndSalary"
            />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Number of Days/Salary Days"
              label-for="num-of-days"
            >     
                <b-form-input
                  class="form-control"
                  id="num-of-days" 
                  v-model="contractualEmployee.numberOfDays" 
                  placeholder="Enter number of Days for Salary"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <b-form-group 
              label="Rate Card"
              label-for="rate-card"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractualEmployee.rateCard" 
                  placeholder="Enter Bill Rate Card in Rupees"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Billing Amount"
              label-for="computed-rate-card"
            >     
              <b-form-input
                class="form-control"
                id="computed-rate-card" 
                v-model="contractualEmployee.computedRateCard" 
                placeholder="Computed Rate Card in Rupees based on Days"
                type="number"
                step=".01"
              />
          </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Employee Salary"
              label-for="emp-salary"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractualEmployee.empSalary" 
                  placeholder="Enter Employee Salary disbursed in Rupees"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                  
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Confirm Salary"
              label-for="computed-salary"
            >     
                <b-form-input
                  class="form-control"
                  id="computed-salary" 
                  v-model="contractualEmployee.computedSalary" 
                  placeholder="Confirm Salary in Rupees based on Days"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
                <small v-if="contractualEmployee.empSalary && contractualEmployee.empSalary !== contractualEmployee.computedSalary" class="text-danger"> {{ contractError }}</small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Revenue Generated"
              label-for="emp-salary"
            >     
              <b-form-input
                class="form-control"
                id="rate-card" 
                v-model="contractualEmployee.revenueGenerated" 
                placeholder="Enter Revenue Generated this Month in Rupees"
                type="number"
                step=".01"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Invoice Id"
              label-for="invoice-id"
            >     
              <b-form-input
                class="form-control"
                id="invoice-id" 
                v-model="contractualEmployee.invoiceId" 
                placeholder="Enter Candidate Invoice ID"
                @input="computeRateAndSalary"
              />
            </b-form-group>
          </b-col>
          
        </b-row>  
        
        
      </b-form>
    </b-modal>
    <b-modal
      id="modal-contractual-update-data"
      ref="modal-contractual-update-data"
      :title="cname + ' - ' + position + ' - ' + client"
      ok-title="submit"
      cancel-variant="outline-secondary"
      size="lg"
      v-if="$can('update','contractual_candidates')"
      @ok="updateContractualData(contractualEmployee.id, contractualEmployee.candidateId, contractualEmployee.jobId)"
    >
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Contract Start Date"
              label-for="contract-start-date"
            >
              <flat-pickr
                  class="form-control"
                  id="contract-start-date" 
                  v-model="contractualEmployee.contractStartDate" 
                  placeholder="YYYY-MM-DD"
                  :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Contract End Date"
              label-for="contract-end-date"
            >
            <flat-pickr
                class="form-control"
                id="contract-end-date" 
                v-model="contractualEmployee.contractEndDate" 
                placeholder="YYYY-MM-DD"
                :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
            />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Contract Month"
              label-for="contract-month"
            >
            <v-select
                id="contract-month" 
                v-model="contractualEmployee.contractMonth" 
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="YYYY-MM-DD"
                :options="monthsList"
                :reduce="(option) => option.title"
                @change="computeRateAndSalary"
            />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Number of Days/Salary Days"
              label-for="num-of-days"
            >     
                <b-form-input
                  class="form-control"
                  id="num-of-days" 
                  v-model="contractualEmployee.numberOfDays" 
                  placeholder="Enter number of Days for Salary"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
            </b-form-group>
          </b-col>
          
          <b-col md="6">
            <b-form-group 
              label="Rate Card"
              label-for="rate-card"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractualEmployee.rateCard" 
                  placeholder="Enter Bill Rate Card in Rupees"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Billing Amount"
              label-for="computed-rate-card"
            >     
              <b-form-input
                class="form-control"
                id="computed-rate-card" 
                v-model="contractualEmployee.computedRateCard" 
                placeholder="Computed Rate Card in Rupees based on Days"
                type="number"
                step=".01"
              />
          </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Employee Salary"
              label-for="emp-salary"
            >     
                <b-form-input
                  class="form-control"
                  id="rate-card" 
                  v-model="contractualEmployee.empSalary" 
                  placeholder="Enter Employee Salary disbursed in Rupees"
                  type="number"
                  step=".01"
                  
                  
                />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Confirm Salary"
              label-for="computed-salary"
            >     
                <b-form-input
                  class="form-control"
                  id="computed-salary" 
                  v-model="contractualEmployee.computedSalary" 
                  placeholder="Confirm Salary in Rupees based on Days"
                  type="number"
                  step=".01"
                  @input="computeRateAndSalary"
                />
                <small v-if="contractualEmployee.empSalary && contractualEmployee.empSalary !== contractualEmployee.computedSalary" class="text-danger"> {{ contractError }}</small>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Revenue Generated"
              label-for="emp-salary"
            >     
              <b-form-input
                class="form-control"
                id="rate-card" 
                v-model="contractualEmployee.revenueGenerated" 
                placeholder="Enter Revenue Generated this Month in Rupees"
                type="number"
                step=".01"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group 
              label="Invoice Id"
              label-for="invoice-id"
            >     
              <b-form-input
                class="form-control"
                id="invoice-id" 
                v-model="contractualEmployee.invoiceId" 
                placeholder="Enter Candidate Invoice ID"
              />
            </b-form-group>
          </b-col>
          
        </b-row>  
        
        
      </b-form>
    </b-modal>
    
    
    </div>
  </template>
  
  <script>
  import {
    BTable, BAvatar, BBadge, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, BModal, VBModal, BDropdown, BDropdownItem, BFormRadioGroup, VBPopover, 
  } from 'bootstrap-vue'
  import CandidateService from '@/services/candidate-service.js'
  import vSelect from 'vue-select'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
  import jobTimelineService from '@/services/job-timeline'
  import candidateService from "@/services/candidate-service";
  import clientService from '@/services/client-service'
  import flatPickr from 'vue-flatpickr-component'
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import UserService from '@/services/user-service'
  import interviewService from '@/services/interview-service'
  // import { debounce } from 'lodash';

  import contractualService from '@/services/contractual-service'
  import contractualConfigService from '@/services/contractual-config-service'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import adminConfigService from '@/services/admin-config-service'

  
  export default {
    components: {
      BTable,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCard,
      BFormRadioGroup,
      //BPopover,
      AppCollapse,
      AppCollapseItem
    },
    data() {
      return {
        perPage: 50,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filename: '',
        isReadyToDownload: false,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: "actions", label: 'EDIT', thStyle: { width: "3%" } },
          {
            key: 'candidate_name', label: 'Name', sortable: true, thStyle: { width: "15%" } 
          },
          {
            key: 'position_name', label: 'Position', sortable: true, thStyle: { width: "15%" } 
          },
          { key: 'candidateStatus', label: 'status', sortable: true, thStyle: { width: "10%" }  },
          { key: 'sourcer', label: 'Sourcer / Recruiter', sortable: true, thStyle: { width: "10%" }  },
          { key: 'createdAt', label: 'Added On', sortable: true, thStyle: { width: "10%" }  },
          //{ key: 'updatedAt', label: 'Last Updated', sortable: true },
          { key: 'selection_date', label: 'Selected On', sortable: true, thStyle: { width: "10%" } },
          { key: 'offered_date', label: 'Offered On', sortable: true, thStyle: { width: "10%" } },
          { key: 'joining_date', label: 'Joining on', sortable: true, thStyle: { width: "10%" } },
          { key: 'offered_ctc', label: 'Offered CTC', sortable: true, thStyle: { width: "10%" } },
          { key: 'revenue', label: 'Revenue', sortable: true, thStyle: { width: "7%" } },
          { key: 'attrition_date', label: 'Attrition Date', sortable: true, thStyle: { width: "7%" } }
        ],
        contractualFields: [
          {key: 'month', label: 'Month'},
          {key: 'start_date', label: 'Start Date'},
          {key: 'end_date', label: 'End Date'},
          {key: 'num_of_days', label: 'Num Of Days'},
          {key: 'rate_card', label: 'Rate Card'},
          {key: 'computed_rate_card', label: 'Computed Rate Card'},
          {key: 'salary', label: 'Salary'},
          {key: 'computed_salary', label: 'Confirm Salary'},
          {key: 'revenue', label: 'Revenue'},
          {key: 'invoice_id', label: 'Invoice Id'},
          {key: "actions", label: 'EDIT', thStyle: { width: "3%" } },
        ],
        contractualDetailFields: [
          {key: 'start_date', label: 'Start Date'},
          {key: 'end_date', label: 'End Date'},
          {key: 'rate_card', label: 'Rate Card'},
          {key: 'monthly_ctc', label: 'Monthly CTC'},
          {key: 'probation_days', label: 'Probation Days'},
          {key: "actions", label: 'EDIT', thStyle: { width: "3%" } },
        ],
        contractualItems: [],
        contractualDetailItem: [],
        items: [],
        error: '',
        statusWiseData: [],
        candidates: [],
        selections: 0,
        offered: 0,
        joined: 0,
        selected: 'NO',
        option: ['YES', 'NO'],
        cid: 0,
        jobid: 0,
        cname: '',
        position: '',
        client: '',
        offeredCTC: null,
        selection_date: null,
        offered_date: null,
        joining_date: null,
        attrition_date: null,
        duplicate_date: null,
        attrition_type: null,
        attrition_reason: null,
        interview_date: '',
        revenue: 0,
        revenue_confirmed: false,
        currentStatus: '',
        response_error: '',
        role: '',
        //statusSelected: '',
        statusOptions: [
            { value: "All", title: "All" },
            { value: "UploadedBySourcer", title: "UploadedBySourcer" },
            { value: "UploadedByRecruiter", title: "UploadedByRecruiter" },
            { value: "AssignedByMassMail", title: "AssignedByMassMail" },
            { value: "Wrong Number", title: "Wrong Number" },
            { value: "Not Answered", title: "Not Answered" },
            { value: "Not Reachable", title: "Not Reachable" },
            { value: "Not Interested", title: "Not Interested" },
            { value: "Not Relevant", title: "Not Relevant" },
            { value: "Call Back", title: "Call Back" },
            { value: "ProfileShared", title: "ProfileShared" },
            { value: "ReviewReject", title: "ReviewReject" },
            { value: "Initiated", title: "Initiated" },
            { value: "Screen Reject", title: "ScreenReject" },
            { value: "Screen Select", title: "ScreenSelect" },
            { value: "Duplicate", title: "Duplicate" },
            { value: "Inprocess", title: "Inprocess" },
            { value: "OnHold", title: "OnHold" },
            { value: "Shortlisted", title: "Shortlisted" },
            { value: "Offered", title: "Offered" },
            { value: "Not Offered", title: "NotOffered" },
            { value: "Joined", title: "Joined" },
            { value: "Offer Drop", title: "OfferDrop" },
            { value: "Rejected", title: "Rejected" },
            { value: "Early Attrition", title: "Early Attrition" },
            { value: "Attrition", title: "Attrition" },
            { value: "Confirmed", title: "Confirmed" },
            { value: "LOI Issued", title: "LOI Issued" },
            { value: "Document Submitted", title: "Document Submitted" },
            { value: "Drop", title: "Drop" },
        ],
        revenues: [{title: "Confirmed", value: true},{title: "Not Confirmed", value: false}, {title: "Blank", value: "NULL"}],
        //revenueSelected: '',
        recruiters: [],
        //recruiterSelected: '',
        accountManagers: [],
        //managerSelected: null,
        // startDate: '',
        // endDate: '',
        all_ids: "",
        // cid: null,
        // jid: null,
        dateFieldOptions: [
          { text: 'Joining Date', value: 'joining' },
          { text: 'Added Date', value: 'added' },
          { text: 'Updated Date', value: 'updated' }
        ],
        searchFilters: {
          dateFieldSelected: 'joining',
          jobStatus: 'ALL',
          startDate: '',
          endDate: '',
          cid: '',
          jid: '',
          recruiterSelected: '',
          managerSelected: '',
          revenueSelected: '',
          statusSelected: '',
          clientSelected: '',
          filter: '',
          jobType: '',
          payroll: '',
          category: '',
          vertical: ''
        },  
        jobStatusOptions:  [
          { title : "ALL", value: 'ALL' },  
          { title : "Active", value: 'Active' },
          { title : "Closed", value: 'Closed' },
          { title : "OnHold", value: 'OnHold' },
          { title : "Cancelled", value: 'Cancelled' },
        ],
        jobTypeOptions: [{title: "Full Time"}, {title: "Contractual"}, {title: "Fixed Term"}, {title: "Cost Plus"}],
        payrollOptions: [{title: "Client Payroll"}, {title: "Sattva Payroll"}, {title: "Third Party"}],
        clients: [],
        filterContractual: null,
        contractualEmployee: {
          contractStartDate : null,
          contractEndDate: null,
          rateCard: null,
          computedRateCard: null,
          empSalary: null,
          revenueGenerated: null,
          contractMonth: null,
          candidateId: null,
          jobId: null,
          numberOfDays: null,
          invoiceId: null,
          probationDays: null,
          monthlyCTC: null,
        },
        contractDetails: {
          contractStartDate : null,
          contractEndDate: null,
          rateCard: null,
          monthlyCTC: null,
          probationDays: null,
          status: null
        },
        monthsList: [],
        variableCTC: null,
        band: null,
        canData: null,
        contractError: null,
        attritionTypeOptions: [
          {"title": "Voluntary"},
          {"title": "Involuntary"}
        ],
        attritionReasonOptions: [{}],
        categoryOptions: [{title: 'White Collar'}, {title: 'Blue Collar'}],
        verticalOptions: [{title: 'Service Industry'}, {title: 'Infra Industry'}],
        contractDataPresent: false,
        contractualConfigData: null,
        totalCount: null
        // showCTCRevenueDetails: true
        //jobStatus: 'ALL',
        

      }
    },
    components: {
        BTable, BAvatar, BBadge, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCard, vSelect, BModal, VBModal, flatPickr, BDropdown, BDropdownItem, BFormRadioGroup, AppCollapse, AppCollapseItem
    },
    directives: {
        'b-modal': VBModal,
        'b-popover': VBPopover,
        Ripple,
    },
    watch: {
      searchFilters: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.searchFilters = { ...this.searchFilters, ...to.query };
      },

    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
      showCTCRevenue(client) {
        // if(client === 'Sattva Human Pvt Ltd' && (this.role === 'TALENT-ADVISOR-SOURCER' || this.role === 'TALENT-ADVISOR' || this.role === 'ACCOUNT-MANAGER' )) {
        if(client === 'Sattva Human Pvt Ltd' && (this.$can('read','talent_acquisition') || this.$can('read','sme_talent_acquisition') || this.$can('read','account-manager') || this.$can('read','program-manager') )) {
          return false
        } else {
          return true
        }
        
      }
    },
    mounted() {
      // Set the initial number of items
      
      
    },
    created() {
      console.log('route queries', this.searchFilters)
      // this.searchFilters.statusSelected = 'Joined'
      // this.searchFilters.recruiterSelected = 'SH-018'
      if(this.$route.query) {
          //this.searchFilters = { ...this.searchFilters, ...this.$route.query };
          this.searchFilters.recruiterSelected = this.$route.query.recruiterSelected ? this.$route.query.recruiterSelected : ''
          this.searchFilters.managerSelected = this.$route.query.managerSelected ? this.$route.query.managerSelected : ''
          this.searchFilters.revenueSelected = this.$route.query.revenueSelected ? this.$route.query.revenueSelected : ''
          this.searchFilters.dateFieldSelected = this.$route.query.dateFieldSelected ? this.$route.query.dateFieldSelected : 'joining'
          this.searchFilters.jobStatus = this.$route.query.jobStatus ? this.$route.query.jobStatus : ''
          this.searchFilters.statusSelected = this.$route.query.statusSelected ? this.$route.query.statusSelected : ''
          this.searchFilters.clientSelected = this.$route.query.clientSelected ? this.$route.query.clientSelected : ''
          this.searchFilters.startDate = this.$route.query.startDate ? this.$route.query.startDate : ''
          this.searchFilters.endDate = this.$route.query.endDate ? this.$route.query.endDate : ''
          this.searchFilters.filter = this.$route.query.filter ? this.$route.query.filter : ''
          this.searchFilters.cid = this.$route.query.cid ? parseInt(this.$route.query.cid) : ''
          this.searchFilters.jid = this.$route.query.jid ? parseInt(this.$route.query.jid) : ''
          this.searchFilters.payroll = this.$route.query.payroll ? this.$route.query.payroll : ''
          this.searchFilters.jobType = this.$route.query.jobType ? this.$route.query.jobType : ''
          this.searchFilters.category = this.$route.query.category ? this.$route.query.category : ''
          this.searchFilters.vertical = this.$route.query.vertical ? this.$route.query.vertical : ''
          
        }
        if(this.$can('read','vertical_data') && this.$can('read','vertical_infra') && this.$can('read','vertical_service')) {
          this.verticalOptions = [{title: 'Service Industry'}, {title: 'Infra Industry'}]
        } else if (this.$can('read','vertical_data') && this.$can('read','vertical_infra')) {
          this.verticalOptions = [{title: 'Infra Industry'}]
          this.searchFilters.vertical = 'Infra Industry'
        } else if (this.$can('read','vertical_data') && this.$can('read','vertical_service')) {
          this.verticalOptions = [{title: 'Service Industry'}]
          this.searchFilters.vertical = 'Service Industry'
        }
    // this.searchFilters.startDate = moment().locale("en").add(-1, 'days').format("YYYY-MM-DD")
    // this.searchFilters.endDate = moment().locale("en").add(0, 'days').format("YYYY-MM-DD")
      if(this.$can('read','candidates')) {
        this.getMyCandidates()
      }
      
      this.role = JSON.parse(localStorage.getItem('userData')).roles[0]
      const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id
      const title = JSON.parse(localStorage.getItem('userData')).first_name + ' ' + JSON.parse(localStorage.getItem('userData')).last_name
      // this.recruiters = [{
      //     "id": 1,
      //     "title": "All",
      //     "emp_id": "All"
      // }]
      // UserService.getAllUsersDd().then( res => {
      // if(res.status === "OK") {
      //   console.log("in if")

      UserService.getAllTeamMembersList(emp_id).then(res =>{
        console.log('recruiters ',res.data)
        this.recruiters = res.data
        for (let i = 0; i < this.recruiters.length; i++) {
              const id = "'" + this.recruiters[i].emp_id + "'|"
              // console.log(id)
              this.all_ids = this.all_ids + id
        }
      })
      UserService.getAllManagersList().then(res =>{
        console.log('managers ',res.data)
        this.accountManagers = res.data
        // for (let i = 0; i < this.recruiters.length; i++) {
        //       const id = "'" + this.recruiters[i].emp_id + "'|"
        //       // console.log(id)
        //       this.all_ids = this.all_ids + id
        // }
      })

      // if(this.$can('read','manager_filter')) {
      //   UserService.getAllManagersList(emp_id).then(res =>{
      //     this.recruiters = res.data
      //   })
      // }
          clientService.getClients().then(res => {
            this.clients = res.data
            console.log(this.clients)
          })
          const allUsers = JSON.parse(localStorage.getItem('allusers'))
          // this.accountManagers = allUsers.filter(item => (item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase()));
          
        //   console.log('allUsers', allUsers)
        //   if(this.$can('read','manager_filter')) {
        //     this.accountManagers = allUsers.filter(item => item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() );
        //     if(this.$can('read','program_manager')) {

        //     }
        //   }
          
        //   if (this.role === 'PROGRAM-MANAGER') {
        //     console.log('in role pm')
        //     const self = [{
        //       "id": 111112,
        //       "title": title,
        //       "emp_id": emp_id
        //     }]
        //     const ams = allUsers.filter(item => (item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase()) && item.manager === emp_id);

        //     let recs = []
        //     ams.forEach(am => {
        //       const tmp_recs = allUsers.filter(item => (item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase()) && item.manager === am.emp_id);
        //       recs = [...recs,...tmp_recs]
        //     });
        //     // recs = [...recs]
        //     this.recruiters = [...this.recruiters, ...self, ...ams, ...recs]
        //     console.log('program manager recruiter list: ', this.recruiters)

        //     for (let i = 0; i < this.recruiters.length; i++) {
        //       const id = "'" + this.recruiters[i].emp_id + "'|"
        //       console.log(id)
        //       this.all_ids = this.all_ids + id
        //     }
        //     console.log(this.all_ids)
        //   } 
        //   else if (this.role === 'ACCOUNT-MANAGER') {
        //     const self = [{
        //       "id": 111111,
        //       "title": title,
        //       "emp_id": emp_id
        //     }]
          
        //   const recs = allUsers.filter(item => (item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase()) && item.manager === emp_id);  
        //   this.recruiters = [...this.recruiters, ...self, ...recs]
        //   //this.accountManagers = allUsers.filter(item => item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() );
        //   console.log('recruiter:', this.recruiters, 'managers: ', this.accountManagers)
        //   for (let i = 0; i < this.recruiters.length; i++) {
        //     const id = "'" + this.recruiters[i].emp_id + "'|"
        //     console.log(id)
        //     this.all_ids = this.all_ids + id
        //   }
        //   console.log(this.all_ids)
        // } else if (this.role === 'TALENT-ADVISOR' || this.role === 'TALENT-ADVISOR-SOURCER') {
        //   const recs = []
        //   this.recruiters = [...this.recruiters, ...recs]
        // } else {
        //   const recs = allUsers.filter(item => item.role.toLowerCase() === "TALENT-ADVISOR-SOURCER".toLowerCase() || item.role.toLowerCase() === "ACCOUNT-MANAGER".toLowerCase() || item.role.toLowerCase() === "TALENT-ADVISOR".toLowerCase());
        //   this.recruiters = [...this.recruiters, ...recs]
        // }

        //this.debouncedFilter = debounce(this.onFiltered, 300);
     // }
    //})
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      getMyCandidates(s) {
        this.loading = true
        if(s) {
          this.searchFilters.statusSelected = s
        }
        const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
        const emp_id = JSON.parse(localStorage.getItem('userData')).emp_id

        let ids = ""
        

        //console.log(this.searchFilters.recruiterSelected, this.searchFilters.statusSelected)
        //Program Manager sector wise data is handled by passing sector and not ids
        if(this.$can('read','admin') || this.$can('read','hr') || this.$can('read','master') || this.$can('read','mis')) {
          // for admin, master, hr,mis, no condition 
          ids = emp_id
          console.log('other ids',ids)
        } else if(this.$can('read','account_manager') ) {
          this.all_ids = this.all_ids.substring(0, this.all_ids.length-1);
          ids = this.searchFilters.recruiterSelected ? (this.searchFilters.recruiterSelected === 'All' ? this.all_ids : this.searchFilters.recruiterSelected) : emp_id
          console.log('account_manager',ids)
        } else if(this.$can('read','talent_acquisition')) {
          ids = emp_id
          console.log('talent advisor',ids)
        }

        const d = {
            role: userRole,
            rec: this.searchFilters.recruiterSelected ? this.searchFilters.recruiterSelected : '',
            status: this.searchFilters.statusSelected ? this.searchFilters.statusSelected : '',
        }
        this.searchFilters.startDate = this.searchFilters.startDate ? this.searchFilters.startDate : ''
        this.searchFilters.endDate = this.searchFilters.endDate ? this.searchFilters.endDate : ''
        this.searchFilters.managerSelected = this.searchFilters.managerSelected ? this.searchFilters.managerSelected : ''

        console.log('date Selected', this.searchFilters.dateFieldSelected, d)
        CandidateService.getMyCandidates(ids, d, this.searchFilters.startDate, this.searchFilters.endDate, this.searchFilters.revenueSelected, this.searchFilters.cid, this.searchFilters.jid, this.searchFilters.dateFieldSelected, this.searchFilters.jobStatus, this.searchFilters.clientSelected, this.searchFilters.jobType, this.searchFilters.payroll, this.searchFilters.managerSelected, this.searchFilters.category, this.searchFilters.vertical).then(res => {
                console.log('my candidates', res)
                if(res.status === "OK") {
                  this.error = null
                  // this.searchFilters = {
                  //   statusSelected: d.status,
                  //   recruiterSelected: d.rec,
                  //   role: d.role,
                  //   startDate: this.searchFilters.startDate,
                  //   endDate: this.searchFilters.endDate,
                  //   revenueSelected: this.searchFilters.revenueSelected,
                  //   cid: this.searchFilters.cid,
                  //   jid: this.searchFilters.jid,
                  //   dateFieldSelected: this.searchFilters.dateFieldSelected,
                  //   jobStatus: this.searchFilters.jobStatus  

                  // }

                    if(this.$route.query.candidate_id && this.$route.query.candidate_id.length > 0 && this.$route.query.job_id && this.$route.query.job_id.length > 0) {
                      console.log(res.data.candidates, this.$route.query.candidate_id, this.$route.query.job_id)

                      this.items = res.data.candidates.filter(item => item.cid === this.searchFilters.cid && item.jid === this.searchFilters.jid )
                      this.candidates = res.data.candidates.filter(item => item.cid === this.searchFilters.cid && item.jid === this.searchFilters.jid )
                      console.log('route query', this.items)
                      this.totalRows = this.items.length
                      this.totalCount = res.data.totalCount
                      this.statusWiseData = null
                      this.filename = 'Candidate Tracker.xls'
                      this.isReadyToDownload = true
                    } else {
                      this.items = res.data.candidates
                      this.candidates = res.data.candidates
                      this.totalRows = this.items.length
                      this.statusWiseData = res.data.statusWise
                      this.totalCount = res.data.totalCount
                      this.filename = 'Candidate Tracker.xls'
                      this.isReadyToDownload = true
                    }
                    
                    this.loading = false



                    // this.joined = this.items.filter(item => item.candidateStatus === 'Joined')
                    // this.selections = this.items.filter(item => item.candidateStatus === 'Shortlisted')
                    // this.offered = this.items.filter(item => item.candidateStatus === 'Offered')
                }  else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                this.error = 'Session Expired... Redirecting to Login Page'
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
                } else if ((res.status === 400 ) ) {
                this.error = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
                //   this.loading = false
                //   this.compiledData = null
                }
                else if ((res.status === 500 ) ) {
                this.error = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
                //this.loading = false
                //this.compiledData = null
                } else {
                    this.error = res
                }

        })
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
        const element = document.getElementById("my-candidates-table");
        element.scrollIntoView();
      },
      onFilteredContractual(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRowsContractual = filteredItems.length
      },
      showContractualCandidateData(data) {
        if(!this.$can('read','contractual_candidates')) {
          return
        }
        this.canData = data;
        this.sendCandidateItemToModal(data)
        console.log('candidate data from b-table: ', data)
        // contractualService
        // contractualConfigService.findByCandidate.findByCandidate(data.cid, data.jid).then(res =>{
        //   console.log('contractual Config data: ', res)
        //   if(res.status === 'OK') {
        //     this.contractualConfigData = res.data
        //   } else {
        //     console.log('Some error occurred', res)
        //   }
        // })
        this.contractualDetailItem = null
        contractualConfigService.findByCandidate(this.searchFilters.cid,this.jobid).then(res => {
          if(res.status === 200) {
            this.contractualDetailItem = res.data.data
            console.log('get contractual candiadte data: ', this.contractualDetailItem)
            this.contractDetails = {
              contractStartDate: this.contractualDetailItem[0].start_date,
              contractEndDate: this.contractualDetailItem[0].end_date,
              rateCard: this.contractualDetailItem[0].rate_card,
              monthlyCTC: this.contractualDetailItem[0].monthly_ctc,
              probationDays: this.contractualDetailItem[0].probation_days
            }
            console.log('contract details: ', this.contractDetails)
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Candidate Contract Details fetch Successful!!!",
            //     icon: "EditIcon",
            //     variant: "primary",
            //   },
            // });
          } else if(res.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error:" + res.data,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else if(res.status === 401 || res.status === 403) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Login Session Over or Unauthorized User" ,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Login Session Over or Unauthorized User" ,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          }
        })

        contractualService.findByCandidate(data.cid, data.jid).then(res =>{
          console.log('contractual items: ', res)
          if(res.status === 'OK') {
            this.contractualItems = res.data
          } else {
            console.log('Some error occurred', res)
          }
          

        })

      },
      sumOfContractualEmpRevenue(dataArray) {
    // Check if the array is null or not an array
          if (!Array.isArray(dataArray)) {
              return 0; // Return 0 if it's not an array or if it's null
          }

          // Use reduce to calculate the sum
          const totalRevenue = dataArray.reduce((acc, item) => {
              // Check if the revenue exists and is a number, else add 0
              const revenue = Number(item.revenue); // Convert to number in case it's a string number
              return acc + (isNaN(revenue) ? 0 : revenue);
          }, 0);

          return totalRevenue;
      },
      computeRateAndSalary() {
        console.log('compute rate and salary called')
        this.contractualEmployee.computedRateCard = this.computeRateCard(this.contractualEmployee.rateCard, this.contractualEmployee.numberOfDays, this.contractualEmployee.contractMonth)
        //this.contractualEmployee.computedSalary = this.computeSalary(this.contractualEmployee.empSalary, this.contractualEmployee.numberOfDays, this.contractualEmployee.contractMonth)
        // if(this.contractualEmployee.empSalary) {
        //   this.contractualEmployee.revenueGenerated = parseFloat(this.contractualEmployee.computedRateCard - this.contractualEmployee.empSalary).toFixed(2)
        // }
        if(this.contractualEmployee.empSalary) {
          this.contractualEmployee.revenueGenerated = parseFloat(this.contractualEmployee.computedRateCard - this.contractualEmployee.empSalary).toFixed(2)
        }
        
        if(this.contractualEmployee.empSalary && this.contractualEmployee.invoiceId ) {

          if(this.contractualEmployee.empSalary !== this.contractualEmployee.computedSalary) {
            this.contractError = 'Employee Salary and Confirm Salary Should be same'
              window.alert(this.contractError)
          }    
          
        }

        
      },
      computeRateCard(totalRateCard, daysWorked, monthYear) {
        // Helper function to get the total days in a month
          function getDaysInMonth(month, year) {
              return new Date(year, month, 0).getDate();
          }

          // Split the monthYear string into components
          const [monthName, year] = monthYear.split('-');
          // Map month names to month numbers (0-indexed as expected by JavaScript Date)
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthIndex = monthNames.indexOf(monthName);

          if (monthIndex === -1) {
              throw new Error('Invalid month name');
          }

          // Calculate the total number of days in the selected month
          const totalDaysInMonth = getDaysInMonth(monthIndex + 1, parseInt(year, 10));

          // Calculate the per day rate card
          const perDayRateCard = totalRateCard / totalDaysInMonth;

          // Compute the rate card for the days worked
          const computedRateCard = perDayRateCard * daysWorked;

          return parseFloat(computedRateCard).toFixed(2);
      },
      computeSalary(totalSalary, daysWorked, monthYear) {
        // Helper function to get the total days in a month
          function getDaysInMonth(month, year) {
              return new Date(year, month, 0).getDate();
          }

          // Split the monthYear string into components
          const [monthName, year] = monthYear.split('-');
          // Map month names to month numbers (0-indexed as expected by JavaScript Date)
          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthIndex = monthNames.indexOf(monthName);

          if (monthIndex === -1) {
              throw new Error('Invalid month name');
          }

          // Calculate the total number of days in the selected month
          const totalDaysInMonth = getDaysInMonth(monthIndex + 1, parseInt(year, 10));

          // Calculate the per day rate card
          const perDaySalary = totalSalary / totalDaysInMonth;

          // Compute the rate card for the days worked
          const computedSalary = perDaySalary * daysWorked;

          return parseFloat(computedSalary).toFixed(2);
      },
      openAddContractDetailsModal() {      
        this.$refs["modal-contractual-details-add-data"].show();
      },
      addContractualDetails() {
        if(!this.$can('create','contractual_candidates')) {
          return
        }
        // this.contractDetails = null
        
        let status = null
        const currentDate = new Date()
        // var start = moment(this.contractDetails.contractStartDate);
        // var end = moment(currentDate);

        var start = moment(this.contractDetails.contractStartDate);
        var end = moment(currentDate);
        var contractEndDate = moment(this.contractDetails.contractEndDate);
        var attritionDate = this.attrition_date ? moment(this.attrition_date) : null;

        
        var diff = end.diff(start, "days")
        
        if(this.currentStatus === 'Joined' && !this.attrition_date && contractEndDate.isAfter(currentDate)) {
          status = 'ACTIVE'
        } else if (this.attrition_date && attritionDate.isBefore(contractEndDate) && diff < 90){
          status = 'EARLY ATTRITION'
        } else if (this.attrition_date && attritionDate.isBefore(contractEndDate) && diff >= 90){
          status = 'ATTRITION'
        } else if (contractEndDate.isBefore(currentDate)) {
          status = 'EXPIRED';
        } else {
          status = 'EXPIRED'
        }
        console.log('Contract Status detail: ',this.currentStatus, this.attrition_date, this.contractDetails, diff, status )
        const contractDetails = {
          candidate_id: this.searchFilters.cid,
          job_id: this.jobid,
          start_date: this.contractDetails.contractStartDate,
          end_date: this.contractDetails.contractEndDate,
          rate_card: this.contractDetails.rateCard,
          monthly_ctc: this.contractDetails.monthlyCTC,
          probation_days: this.contractDetails.probationDays,
          status: status
        }
        
        contractualConfigService.create(contractDetails).then(res => {
          if(res.status === 201) {
            // this.contractDetails = res.data.data
            this.contractualDetailItem[0] = res.data.data
            console.log('contractual detail after create ', this.contractualDetailItem)
            this.contractDetails = {
              contractStartDate: this.contractualDetailItem[0].start_date,
              contractEndDate: this.contractualDetailItem[0].end_date,
              rateCard: this.contractualDetailItem[0].rate_card,
              monthlyCTC: this.contractualDetailItem[0].monthly_ctc,
              probationDays: this.contractualDetailItem[0].probation_days
            }

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Candidate Contract Details Added Successfully!!!",
                icon: "EditIcon",
                variant: "primary",
              },
            });
          } else if(res.status === 200) {
            
            this.contractualDetailItem[0] = res.data.data
            console.log('contractual detail after update ', this.contractualDetailItem)
            this.contractDetails = {
              contractStartDate: this.contractualDetailItem[0].start_date,
              contractEndDate: this.contractualDetailItem[0].end_date,
              rateCard: this.contractualDetailItem[0].rate_card,
              monthlyCTC: this.contractualDetailItem[0].monthly_ctc,
              probationDays: this.contractualDetailItem[0].probation_days
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Candidate Contract Details exists, Updated!!!",
                icon: "EditIcon",
                variant: "primary",
              },
            });
          } else if(res.status === 500) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error:" + res.data,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else if(res.status === 401 || res.status === 403) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Login Session Over or Unauthorized User" ,
                icon: "EditIcon",
                variant: "danger",
              },
            });
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          }
        })
      },
      openAddContractualModal() {
        if(!this.$can('create','contractual_candidates')) {
          return
        }
          this.$refs["modal-contractual-add-data"].show();
          const totalRevenue = this.sumOfContractualEmpRevenue(this.contractualItems)

          const contractData = Array.isArray(this.contractualItems) ? this.contractualItems[0] : null

          this.contractualEmployee = {
            contractStartDate : this.contractDetails ? this.contractDetails.contractStartDate : null,
            contractEndDate: this.contractDetails ? this.contractDetails.contractEndDate  : null,
            rateCard: this.contractDetails ? this.contractDetails.rateCard : null,
            empSalary: this.contractDetails ? this.contractDetails.monthlyCTC : null,
            revenueGenerated: contractData ? contractData.revenue : null,
            computedRateCard: contractData ? contractData.computed_rate_card : null,
            computedSalary: this.contractDetails ? this.contractDetails.monthlyCTC : null,
            contractMonth: this.monthsList[0].title,
            candidateId: this.searchFilters.cid,
            jobId: this.jobid
          }

          if(this.contractualEmployee.empSalary && this.contractualEmployee.computedSalary ) {
            if(this.contractualEmployee.empSalary !== this.contractualEmployee.computedSalary) {
              this.contractError = 'Employee Salary and Confirm Salary Should be same'
              //window.alert(this.contractError)
            } 
          }
      },
      openUpdateContractualModal(id,cid,jid) {
        if(!this.$can('update','contractual_candidates')) {
          return
        }
          this.$refs["modal-contractual-update-data"].show();
          const totalRevenue = this.sumOfContractualEmpRevenue(this.contractualItems)

          const contractData = Array.isArray(this.contractualItems) ? this.contractualItems.filter(item => item.id === id)[0] : null

          this.contractualEmployee = {
            contractStartDate : contractData ? contractData.start_date : null,
            contractEndDate: contractData ? contractData.end_date : null,
            rateCard: contractData ? contractData.rate_card : null,
            empSalary: contractData ? contractData.salary : null,
            // contractStartDate: contractData ? contractData.start_date : null,
            // contractEndDate: contractData ? contractData.end_data : null,
            revenueGenerated: contractData ? contractData.revenue : null,
            computedRateCard: contractData ? contractData.computed_rate_card : null,
            computedSalary: contractData ? contractData.computed_salary : null,
            numberOfDays: contractData ? contractData.num_of_days : null,
            contractMonth: contractData ? contractData.month : null,
            invoiceId: contractData ? contractData.invoice_id : null,
            candidateId: cid,
            jobId: jid,
            id: id
          }
          if(this.contractualEmployee.empSalary && this.contractualEmployee.computedSalary ) {
            if(this.contractualEmployee.empSalary !== this.contractualEmployee.computedSalary) {
              this.contractError = 'Employee Salary and Confirm Salary Should be same'
              //window.alert(this.contractError)
            } 
          }
          //this.updateContractualData(id,cid,jid);

      },
      addContractualCandidateData() {
        if(!this.$can('create','contractual_candidates')) {
          return
        }
        console.log('add data check:', this.searchFilters.cid, this.jobid, this.contractualEmployee)
        const data = {
            candidate_id: this.contractualEmployee.candidateId,
            job_id: this.contractualEmployee.jobId,
            month: this.contractualEmployee.contractMonth,
            revenue: this.contractualEmployee.revenueGenerated,
            start_date: this.contractualEmployee.contractStartDate,
            end_date: this.contractualEmployee.contractEndDate,
            rate_card: this.contractualEmployee.rateCard,
            num_of_days: this.contractualEmployee.numberOfDays,
            computed_rate_card: this.contractualEmployee.computedRateCard,
            computed_salary: this.contractualEmployee.computedSalary,
            salary: this.contractualEmployee.empSalary,
            invoice_id: this.contractualEmployee.invoiceId
        }
        // if(this.contractualEmployee !== this.contractualEmployee.empSalary) {
        //   this.$toast({
        //           component: ToastificationContent,
        //           props: {
        //           title: "Salary & Confirm Salary should be same",
        //           icon: "EditIcon",
        //           variant: "danger",
        //           },
        //       });
        //       return;
        // }

        contractualService.create(data).then(res => {
          console.log(res)
          if (res.status === 201) {
            console.log('contractual data updated', res)
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: "Contractual Candidate Details Updated",
                  icon: "EditIcon",
                  variant: "success",
                  },
              });
          } else {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: res.data.message,
                    icon: "EditIcon",
                    variant: "danger",
                  },
              });
          }
        })
        
      },
      updateContractualData(id,cid,jid) {
        if(!this.$can('update','contractual_candidates')) {
          return
        }
        console.log('params: ', id, cid, jid)
        const data = {
            month: this.contractualEmployee.contractMonth,
            revenue: this.contractualEmployee.revenueGenerated,
            start_date: this.contractualEmployee.contractStartDate,
            end_date: this.contractualEmployee.contractEndDate,
            rate_card: this.contractualEmployee.rateCard,
            num_of_days: this.contractualEmployee.numberOfDays,
            computed_rate_card: this.contractualEmployee.computedRateCard,
            computed_salary: this.contractualEmployee.computedSalary,
            salary: this.contractualEmployee.empSalary,
            invoice_id: this.contractualEmployee.invoiceId
        }
        // if(this.contractualEmployee !== this.contractualEmployee.empSalary) {
        //   this.$toast({
        //           component: ToastificationContent,
        //           props: {
        //           title: "Salary & Confirm Salary should be same",
        //           icon: "EditIcon",
        //           variant: "danger",
        //           },
        //       });
        //       return;
        // }

        contractualService.update(id,cid,jid,data).then((res)=>{
          if (res.status === 200) {
            console.log('contractual data updated', res)
              this.$toast({
                  component: ToastificationContent,
                  props: {
                  title: "Contractual Candidate Details Updated",
                  icon: "EditIcon",
                  variant: "success",
                  },
              });
              this.showContractualCandidateData(this.canData)
          } else {
            this.$toast({
                    component: ToastificationContent,
                    props: {
                    title: "Failed !! Contractual Candidate Details Update" + res.data,
                    icon: "EditIcon",
                    variant: "danger",
                  },
              });
          }
        })
      },
      computeContractEndDate() {
        this.contractualEmployee.contractEndDate = moment(this.contractualEmployee.contractStartDate).locale("en").add(365, 'days').format("YYYY-MM-DD")
      },  
      filterCandidates(status) {
        if(status === 'ALL') {
            this.items = this.candidates
        } else {
            this.items = this.candidates.filter(item => item.candidateStatus === status)
        }
        
      },
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      },
      resolveCandidateStatusVariant(status) {
        //console.log("called")
        //const resolveCandidateStatusVariant = status => {
        if (status === 'Uploaded') return 'badge-secondary'
        if (status === 'Initiated') return 'badge-warning'
        if (status === 'ReviewReject') return 'badge-danger'
        if (status === 'ProfileShared') return 'badge-info'
        if (status === 'ScreenReject') return 'badge-danger'
        if (status === 'ScreenSelect') return 'badge-info'
        if (status === 'Duplicate') return 'badge-danger'
        if (status === 'Inprocess') return 'badge-info'
        if (status === 'On-Hold') return 'badge-warning'
        if (status === 'Shortlisted') return 'badge-success'
        if (status === 'Offered') return 'badge-success'
        if (status === 'NotOffered') return 'badge-danger'
        if (status === 'Joined') return 'badge-success'
        if (status === 'OfferDrop') return 'badge-danger'
        if (status === 'Rejected') return 'badge-danger'
        if (status === 'Wrong Number') return 'badge-danger'
        if (status === 'Not Answered') return 'badge-warning'
        if (status === 'Not Reachable') return 'badge-warning'
        if (status === 'Call Back') return 'badge-warning'
        if (status === 'Not Interested') return 'badge-danger'
        if (status === 'Not Relevant') return 'badge-danger'
        if (status === 'UploadedBySourcer') return 'badge-warning'
        if (status === 'UploadedByRecruiter') return 'badge-warning'
        if (status === 'AssignedByMassMail') return 'badge-warning'
        if (status === 'AssignedByATSSourcer') return 'badge-warning'
        if (status === 'Drop') return 'badge-danger'
        if (status === 'Early Attrition') return 'badge-danger'
        if (status === 'Confirmed') return 'badge-info'
        if (status === 'Document Submitted') return 'badge-success'
        if (status === 'LOI Issued') return 'badge-success'
        if (status === 'OnHold') return 'badge-warning'
        return 'badge-secondary'
  //}
     },
     sendCandidateItemToModal(data) {
      console.log(data)
        this.searchFilters.cid = data.cid
        this.jobid = data.jid
        this.cname = data.candidate_name
        this.position = data.position_name
        this.client = data.client
        this.currentStatus = data.candidateStatus
        this.selection_date = data.selection_date
        this.offered_date = data.offered_date
        this.joining_date = data.joining_date
        this.attrition_date = data.attrition_date
        this.offeredCTC = data.offered_ctc
        this.revenue = data.revenue
        this.revenue_confirmed = data.revenue_confirmed
        this.variableCTC = data.variable_ctc
        this.band = data.band
     },
     
     updateCandidateDetails() {
      if(!this.$can('update','candidates')) {
        return
      }
      const userData = JSON.parse(localStorage.getItem("userData"));
      const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
      const username = `${userData.first_name} ${userData.last_name}`
      //const useremail = userData.email
      //let status = {}
      //const text = document.getElementById('statusvalue_' + id).children[0].children[0].children[0].innerHTML
      //const statusSelected = text.match(/([a-zA-Z])/g).join("")
      const update = {
          //status: statusSelected,
          updatedBy: user,
          selection_date: this.selection_date,
          duplicate_date: this.duplicate_date,
          offered_date: this.offered_date,
          joining_date: this.joining_date,
          offered_ctc: this.offeredCTC,
          variable_ctc: this.variableCTC,
          band: this.band,
          revenue: this.revenue,
          revenue_confirmed: this.revenue_confirmed
        };
        
      //console.log('status',statusSelected)
      let description, title = '' 
      if(this.currentStatus === 'Shortlisted') {
        title = `Candidate - ${this.cname} - Added selection date ${this.selection_date}`
        description = 'Selection Date Added'
        this.error = this.selection_date === '' || this.selection_date === null || this.selection_date === undefined ? 'Selection Date is Mandatory' : ''
        //throw new Error(this.error)
      } else if(this.currentStatus === 'Duplicate') {
        title = `Candidate - ${this.cname} - Added duplicate date ${this.duplicate_date}`
        description = 'Duplicate Date Added'
        this.error = this.duplicate_date === '' || this.duplicate_date === null || this.duplicate_date === undefined ? 'Duplicate Date is Mandatory' : ''
        //throw new Error(this.error)
      } else if(this.currentStatus === 'Offered') {
        title = `Candidate - ${this.cname} - Added offer details ${this.offered_date}, doj: ${this.joining_date}, revenue: ${this.revenue}, confirmed: ${this.revenue_confirmed}`
        description = 'Offer details Added'
        this.error = this.offered_date === '' || this.offered_date === null || this.offered_date === undefined ? 'Offered Date is Mandatory' : ''
        if(!this.offeredCTC || this.offeredCTC < 9999) {
          this.error = 'Offered CTC is mandatory!!! & Mention complete CTC like 1000000 if 10 Lacs'
        }

        //throw new Error(this.error)
      } else if(this.currentStatus === 'Joined') {
        title = `Candidate - ${this.cname} - Added DOJ`
        //description = 'Revenue details Added'
        //this.error = this.revenue === 0 || this.revenue === null || this.revenue === undefined ? 'Revenue Details are Mandatory' : ''
        //throw new Error(this.error)
      }
      if(!this.error) {
        const timelineData = {
                title: title,
                description: description, /// CS-Changed means candidate status changed
                type: "success",
                createdBy: username,
                jobId: this.jobid,
                candidate_id: this.searchFilters.cid,
                candidateStatus: this.currentStatus,
                }
        update.jt = timelineData
        candidateService.updateCandidateStatus(this.searchFilters.cid, this.jobid, update).then(
            (res) => {
            console.log(res.data);
            if (res.status === "OK") {
                
                // const timelineData = {
                // title: title,
                // description: description, /// CS-Changed means candidate status changed
                // type: "success",
                // createdBy: username,
                // jobId: this.jobid,
                // candidate_id: this.searchFilters.cid,
                // candidateStatus: this.currentStatus,
                // }
                // console.log(timelineData);
                
                // jobTimelineService.create(timelineData).then(res => {
                // console.log("timeline", res)
                //     if(res.status === "OK") {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                        title: "Candidate Details Updated",
                        icon: "EditIcon",
                        variant: "success",
                        },
                    });
                    this.getMyCandidates()
                    //}
                //})
                
                
            } else if ((res.status === 401 ) || (res.status === 403 )) {
                window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
                localStorage.clear();
                this.$router.push({ name: "auth-login" });
            } else if (res.status === 500 ) {
                this.response_error = res.message + 'Detailed Error: ' + res.error
            } else {
                this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Candidate Details Update Failed",
                    icon: "EditIcon",
                    variant: "danger",
                },
                });
                
            }
            }
        );
      }
     },
     scheduleInterview() {

      if(this.interview_date) {

        const userData = JSON.parse(localStorage.getItem("userData"));
        const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`;
        const username = `${userData.first_name} ${userData.last_name}`
        const mgr_id = userData.mgr_id

        // const datetime = new Date(this.interview_date);

        //   // get the user's timezone offset in minutes
        //   const offset = new Date().getTimezoneOffset();

        //   // convert the offset to milliseconds
        //   const offsetMs = offset * 60 * 1000;

        //   // add the offset to the datetime to get the local time
        //   const localTime = new Date(datetime.getTime() - offsetMs);

        //   console.log(localTime);

        const data = {
          candidate_id: this.searchFilters.cid,
          job_id: this.jobid,
          scheduled_by: userData.emp_id,
          date_time: this.interview_date,
          status: 'Scheduled',
          mgr_id: mgr_id,
          comment: ''
        }
        interviewService.create(data).then(res => {
          if (res.status === "OK") {
            //this.reportStatus = ''
            console.log(res)
            //this.downloadReport = 'data:application/xlsx;base64,' + res.file
            this.$toast({
                          component: ToastificationContent,
                          props: {
                          title: "Interview Scheduled!!!",
                          icon: "EditIcon",
                          variant: "success",
                          },
                      });
            
          } else if ((res.status === 401 ) || (res.status === 403 )) {
            window.sessionStorage.setItem('lastPage', window.location.href.split('/#')[1])
            this.reportStatus = 'Session Expired... Redirecting to Login Page'
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          } else if ((res.status === 400 ) ) {
            this.reportStatus = 'Some Error Occurred, Please Try after sometime. If Same Issue occurs multiple times, Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
          else if ((res.status === 500 ) ) {
            this.$toast({
                          component: ToastificationContent,
                          props: {
                          title: "Interview Schedule Failed!!!",
                          icon: "EditIcon",
                          variant: "danger",
                          },
                      });
            this.reportStatus = 'ERROR: ' + res.data.message + 'Kindly contact IT admin.'
            this.loading = false
            this.compiledData = null
          }
        })
      } else {
        this.error = 'Interview Date is Mandatory'
      }

    },
    clearFilter() {
      this.searchFilters.startDate = ''
      this.searchFilters.endDate = ''
      this.searchFilters.revenueSelected = ''
      this.searchFilters.recruiterSelected = ''
      this.searchFilters.statusSelected = ''
      this.searchFilters.cid = null
      this.searchFilters.jid = null
      this.searchFilters.jobStatus = 'ALL'
      this.searchFilters.dateFieldSelected = 'Joined'
      this.searchFilters.managerSelected = ''
      this.searchFilters.jobType = ''
      this.searchFilters.jobStatus = ''
      this.searchFilters.payroll = ''
      this.searchFilters.category = ''
      this.searchFilters.vertical = ''
      this.searchFilters.clientSelected = ''
      if(this.$route.query) {
        //this.$router.push('/apps/my-candidates')
        this.$router.replace({ query: {} });
      }
      
      // this.$route.query.startDate = null
      // this.$router.query.endDate = null
      // this.$router.recruiterSelected = null
      // this.$route.query.cid = null
      // this.$route.query.jid = null

    },
    getMonthList() {
        const startDate = new Date(2023, 0); // April is represented by index 3
        const currentDate = new Date();
        //const monthsList = [];

        for (let date = startDate; date <= currentDate; date.setMonth(date.getMonth() + 1)) {
            const formattedDate = date.toLocaleString('en-US', { month: 'short', year: 'numeric' }).replace(' ','-');
            const monthObject = { title: formattedDate };
            this.monthsList.push(monthObject);
        }
        this.monthsList.reverse();
        console.log('month options: ', this.monthsList);
    },
    
    // onSearchTermChanged() {
    //   this.debouncedFilter();
    // }
    // rightClicked (item, index, event) {
    //   // Prevent native OS/Browser context menu showing
    //   event.preventDefault();
    //   // this.contextMenuTarget = event.target;
    //   this.showContextMenu = true;
    //   // this.positionContextMenu(event);
    //   // // this.contextMenuStyle.top = `${event.clientY}px`;
    //   // this.contextMenuStyle.left = `${event.clientX}px`;
    // },
    // positionContextMenu(event) {
    //   // const popover = this.$refs.contextMenuPopover;
    //   // if (popover) {
    //   //   popover.$emit('open');
    //   //   popover.$nextTick(() => {
    //   //     const popoverNode = popover.$el;
    //   //     popoverNode.style.top = `${event.clientY}px`;
    //   //     popoverNode.style.left = `${event.clientX}px`;
    //   //   });
    //   // }
    // },
    // actionOne() {
    //   // Handler for Action 1
    // },
    // actionTwo() {
    //   // Handler for Action 2
    // },
    },
    mounted() {
      // Hide the context menu when clicking elsewhere

      this.getMonthList()
      adminConfigService.getJobsDropdownList().then(res=>{
        this.jobStatusOptions = res.data.data.jobStatusList
        this.jobTypeOptions = res.data.data.jobTypeList
        this.categoryOptions = res.data.data.categoryList
        this.payrollOptions = res.data.data.payrollList
        this.verticalOptions = res.data.data.verticalList
      })
    },
    updated: function () {
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
    },
    
    
  }
  </script>
  <style scoped lang="scss">
    .no-bullets {
        list-style-type: none;
    }
    @import "@core/scss/vue/libs/vue-select.scss";
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

  </style>