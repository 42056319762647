import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.34:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';
let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.34:5000/api/';
}
class UserService {
  //Not in use
  getPublicContent() {
    return axios.get(API_URL + 'dashboard/all');
  }
  //Not in use
  getUserBoard() {
    return axios.get(API_URL + 'dashboard/user', { headers: AuthService.authHeader() });
  }
  //Not in use
  getModeratorBoard() {
    return axios.get(API_URL + 'dashboard/mod', { headers: AuthService.authHeader() });
  }
  async getAdminBoard(v) {
    try {
      //console.log(id)
      const res = await axios.get(API_URL + 'dashboard/admin/' + v, 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getUserDetail(id) {
    try {
      console.log(id)
      const res = await axios.get(API_URL + 'user/mgr/' + id, 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err);
      return err
    }
  }
  async getAllUsers() {
    try {
      const res = await axios.get(API_URL + 'user', 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err);
      return err
    }
  }
  async getAllUsersForEvents() {
    try {
      const res = await axios.get(API_URL + 'users/dd', 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getAllUsersDd() {
    try {
      const res = await axios.get(API_URL + 'users', 
      { 
        headers: AuthService.authHeader() 
      });
      // console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getAllUsersDdActiveInactive() {
    try {
      const res = await axios.get(API_URL + 'users/all', 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getAllTeamMembersList(emp_id) {
    try {
      const res = await axios.get(API_URL + 'users/team-members?emp_id=' + emp_id, 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getAllRecruitersList(emp_id) {
    try {
      const res = await axios.get(API_URL + 'users/recruiters', 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async getAllManagersList(emp_id) {
    try {
      const res = await axios.get(API_URL + 'users/managers', 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async deleteUser(id) {
    try {
      const res = await axios.delete(API_URL + 'user' + id, 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err);
      return err
    }
  }
  async updateUser(id) {
    try {
      const res = await axios.put(API_URL + 'user' + id, 
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err);
      return err
    }
  }
  async resetPassword(data) {
    try {
      console.log('in update password')
      const res = await axios.put(API_URL + 'user/reset-pwd/'+ data.email, data,
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err, err.response);
      return err.response
    }
  }
  async pushNotification(payload) {
    try {
    
     const res = await axios.post('https://fcm.googleapis.com/fcm/send', payload, 
     { headers: {
      "Content-Type": "application/json",
      "Authorization": "key=AAAA82TTYu8:APA91bH2cKVmlgmWPWjjrdLImD6hXLu9HEHGrAl1MBNPKgQMo0cRA4nTz-7CkDpGDepUEB7ldK1ccHjnBLCP8a5fZk1MTofWEwDsltmYWuOt_L_tzjCcBDD5s8lUv_cl3bWxY-ujVEyX"
    }})
    console.log(res.data)
    return res.data
    }
    catch(err){
      console.log(err.response)
      return err.reponse
    }
  }
  async setToken(id, token) {
    try {
      const res = await axios.put(API_URL + 'user/token/' + id, token,
        { 
          headers: AuthService.authHeader() 
        }
        );
        console.log(res.data)
        return err.response
         
    }
    catch(err){
      console.log(err.response)
      return err.response
    }
  }
  
  async getToken(body) {
    try {
      const res = await axios.get(API_URL + 'user/token/' + body,
      { 
        headers: AuthService.authHeader() 
      });
      console.log(res.data)
      return res.data
    }
    catch (err) {
      console.log(err)
      console.log(err.response)
      return err.response
    }
  }
  async getUserList() {
    try {
      const res = await axios.get(API_URL + 'user/list',
      { 
        headers: AuthService.authHeader() 
      });
      // console.log(res.data)
      return res
    }
    catch (err) {
      // console.log(err)
      console.log(err.response)
      return err.response
    }
  }
}
export default new UserService();